import aligator from './lottie/aligator.json';
import monkey from './lottie/monkey.json';
import poi from './lottie/poi.json';
import plane from './lottie/plane.json';
import rocket from './lottie/rocket.json';
import squirrel from './lottie/squirrel.json';
import squares from './lottie/squares.json';
import skate from './lottie/skate.json';
import submarine from './lottie/submarine.json';
import wand from './lottie/wand.json';
import waterBack from './lottie/water-back.json';
import waterFront from './lottie/water-front.json';

// import lottie from 'lottie-web';
window.animations = {
  aligator,
  monkey,
  poi,
  plane,
  rocket,
  squirrel,
  submarine,
  squares,
  skate,
  wand,
  waterBack,
  waterFront,
};
